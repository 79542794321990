
import * as React from "react"
import "./layout.css"
const Layout = ({ children }) => {
  return (
    <>
      <div
      >
        <div id="feed" class="feed"><span><a href="https://vipdosug.org/vipdosug.atom"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" aria-hidden="true"><title id="icon-title">Feed</title><path fill="#currentColor" fill-rule="evenodd" d="M10 20c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10ZM6 5.8a8.2 8.2 0 0 1 8.2 8.2h1.6c0-5.412-4.388-9.8-9.8-9.8v1.6Zm0 4a4.2 4.2 0 0 1 4.2 4.2h1.6A5.8 5.8 0 0 0 6 8.2v1.6Zm2 3.7a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" clip-rule="evenodd"></path></svg><span class="label">Feed</span></a></span></div>
        <main>{children}</main>
      </div>
    </>  
  )
}
export default Layout
